.wrapper {
    width: 100%;
    background-color: #c4dcc6;
    position: relative;
}
.font-heading {
    font-family: ffMark-bold;
}
.font-normal {
    font-family: ffMark;
}
.banner-1 {
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: relative;
    text-align: center;
    background-size: cover;
}
.navbar {
    z-index: 100;
    /* font-family: ffMark; */
}

.header {
    display: flex;
    justify-content: space-around;
}
.header a {
    /* text-transform: uppercase; */
    padding-top: 18px;
    font-size: 1.09em;
    color: #152414;
}
.header a:hover {
    text-decoration: underline;
}
.header a:nth-child(3) {
    width: 25%;
    padding: 0;
}

.banner1-text {
    color: #fff;
    font-size: 4em;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.banner-2 {
    background-color: #152414;
    color: #c4dcc6;
    /* padding: 6em 1em; */
}

.banner2-text {
    font-size: 3em;
}
.banner-3 {
    padding-top: 9em;
}

.banner3-img-container {
    width: 100%;
    height: 300px;
    background-color: #152414;
    transition: all 0.3s ease;
}
.banner3-img-container:hover {
    transform: translateY(-5px);
}
.banner3-img-details p:nth-child(0) {
    font-size: 12px;
}
.banner-4 h2 {
    font-size: 4.5em;
    padding-top: 0.5em;
}
.banner-4 {
    padding-top: 2em;
    text-align: center;
}
.banner-4 ul {
    display: inline-block;
}
/* .banner-4 ul:last-child {
    width: 65%;
} */
.banner-4 ul li {
    text-align: left;
}
.banner4-picture {
    width: 200px;
    height: 200px;
    background-color: #bdd4be;
    border-radius: 100px;
    margin-bottom: 2em;
    margin: 0 auto 3em auto;
    transition: all 0.3s ease;
}
.banner4-picture:hover {
    transform: translateY(-5px);
}
.banner-4 h5 {
    font-size: 2em;
    font-weight: 600;
}
.banner-5 {
    padding-top: 5em;
}
.banner-6 {
    background-color: #d3e3d4;
    padding: 5em 0;
}

.goToButton {
    display: inline-block;
    padding: 0.75rem 1.25rem;
    border-radius: 10rem;
    color: #152414;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.goToButton:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #d3e3d4;
    border-radius: 10rem;
    z-index: -2;
    border: 1px solid #152414;
}
.goToButton:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #152414;
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
}
.goToButton:hover {
    color: #fff;
}
.goToButton:hover:before {
    width: 100%;
}

.goToButton:focus {
    box-shadow: none;
}
.arrow {
    position: fixed;
    width: 50px;
    height: 50px;
    right: 2em;
    z-index: 2;
    bottom: 2em;
}
.arrow:hover {
    cursor: pointer;
}
.modal-dialog {
    color: #152414;
}
.footer {
    /* border-top: 1px solid #b9d4bc; */
    background-color: #0c1e0b;
    /* background-color: #152414; */
    
    color: #c4dcc6;
}
.footer .footer-logo {
    width: 12em;
    margin: 0 auto;
}
.footer-logo img {
    width: 100%;
}
.address-block {
    width: 100%;
    font-size: 1em;
}

.mob-view-disappear {
    display: block;
}
.social-link:hover {
    cursor: pointer;
}

/* Generic styling */
.w-80 {
    width: 80%;
}
.font-08em {
    font-size: 0.8em;
}
.font-1em {
    font-size: 1em;
}
.font-2em {
    font-size: 2em;
}
.pb-06 {
    padding-bottom: 6em;
}
.pb-04 {
    padding-bottom: 4em;
}
html {
    scrollbar-width: thin;
}
::-webkit-scrollbar {
    display: none;
}
.bgColor {
    background-color: #152414;
}
.fontWhite {
    color: #fff !important;
}
.round-container {
    border-radius: 100px;
}
.mob-view {
    display: block;
}
.p06em-1em {
    padding: 6em 1em;
}
.logo-bottom {
    letter-spacing: 8px;
    font-size: 2.1em;
    font-weight: bold;
}
.desktopView {
    display: block;
}
.mobView {
    display: none;
}

@media only screen and (max-width: 767px) {
    .mobView {
        display: block;
    }
    .desktopView {
        display: none;
    }
    .social-link {
        text-align: center;
    }
    .banner2-about {
        padding: 4em 1em 0 1em;
    }
    .mob-view-disappear {
        display: none;
    }
}

@media only screen and (max-width: 991px) {
    .navbar {
        background-color: #152414;
    }

    .mob-view-logo {
        width: 30%;
    }
    .mob-view {
        display: none;
    }
    .navbar a {
        color: #fff !important;
    }
    .navbar-toggler-icon {
        width: 80%;
    }
    .banner1-text {
        font-size: 3em;
    }
    .header a {
        font-size: 0.8em;
        padding-top: 9px;
    }
    .banner-4 h2 {
        font-size: 3em;
    }
}

@media only screen and (max-width: 500px) {
    .mob-view-logo {
        width: 50% !important;
    }
    .banner1-text {
        font-size: 2.2em !important;
    }
    .banner-4 h2 {
        font-size: 2em;
    }

    .address-block {
        font-size: 0.8em;
    }

    .address-flag {
        width: 100% !important;
        font-size: 0.8em;
    }
    .social-link {
        padding-right: 0;
    }
}
