

@font-face {
  font-family: 'ffMark-bold';
  src: local('ffMark-bold'), url(./fonts/Mark-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'ffMark';
  src: local('ffMark'), url(./fonts/Mark-Medium.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: ffMark !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}